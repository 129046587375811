import React from "react";
import Navbar from "./Components/Navbar";
import Hero from "./Components/Hero";
import Faq from "./Components/Faq";
import Footer from "./Components/Footer";
import myVideo from "./assets/video/video.mp4";
import Partners from "./Components/Partners";

const App = () => {
  return (
    <div>
        <div class="w-embed">
        <video autoPlay loop muted class="video-bg">
          <source src={myVideo} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
      </div>
       <div className=" relative flex flex-col gap-5 md:gap-12 p-3 md:p-7 max-w-[1300px] mx-auto !pb-28 z-20">
        <Navbar />
        <Hero />
        <Partners/>
        {/* <Faq /> */}
        <Footer />
      </div>
    </div>
    
   
  );
};

export default App;
