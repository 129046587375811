import React from 'react'
import Nrkcoin from '../assets/images/Nrkcoin.png';
import Starcoin from '../assets/images/star2.svg';
import Coin from '../assets/images/star2.svg';
// import LandingPage from "./Comingsoon";

const Hero = () => {
  return (
    <div>
    {/* <div className="flex flex-col gap-2.5 md:gap-5 md:flex-row md:items-start mb-10">
        <div className="flex md:w-full">
          <div className="flex flex-col md:flex-row items-center justify-center gap-1.5 md:gap-4 w-full p-5 md:p-6 md:px-0 bg-[#aea8ff87] rounded-2xl">
            <div className="container">
              <h1 className="uppercase text-center mb-2 bg-gradient-to-r font-extrabold text-2xl from-[#FFD600] to-[#FFEC86]/90 text-transparent bg-clip-text">
                Pre-Sale Will Go Live
              </h1>
              <LandingPage targetDate="2024-03-03T17:00:00" />
            </div>
          </div>
        </div>
    </div> */}
    <div className="flex flex-col sm:flex-row gap-5 md:gap-8 md:w-full" id="hero">      
      <div className="flex item-center m-auto flex-col gap-2.5 fade-in-left md:w-1/2">
        <img src={Coin} className='w-55 m-auto'/>        
        <h1 className="text-center text-3xl font-extrabold italic uppercase md:text-5xl md:text-center md:w-full ">
          STARNEST
        </h1>
        <p className="text-center text-sm md:text-center md:text-xl md:w-full font-light">        
          <span className="font-semibold">
            A cosmic galaxy where your NFTs come to life, blending real estate, staking, and token minting.
          </span> 
        </p>
      </div>     
    
    <div className="flex flex-col gap-2.5 md:gap-5 md:flex-row justify-center md:w-1/2">
      <div className="flex flex-col gap-5 md:w-3/4">
        <div className="bg-[#080531] rounded-2xl" id="presale-form">
          <div className="p-5 md:p-8 space-y-5">
              <div class="space-y-5 text-center">
                {/* <p class="text-sm text-white">10% upon first listing, followed by 30% each month. Secure your investment now and enjoy a dynamic token distribution</p> */}
                <p class="text-sm text-white">Listing on $0.00001</p>
                <p class="text-sm text-white">Buying Price = $0.000001</p>
                <hr class="border-white/10"/>                
            </div>
            
            <div className="space-y-2">
              <label
                for="from-token"
                className="text-xs font-semibold uppercase text-white"
              >
                Amount in <span className="text-primary">$NRK</span> you pay
              </label>
              <div className="relative">
                <input
                  type="number"
                  className="w-full p-5 pr-16 bg-transparent border rounded-lg border-white/20 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-5">
                  <img
                    src={Nrkcoin}
                    alt="Nrk"
                    className="object-contain w-6 h-6"
                  />
                </div>
              </div>
            </div>
            <div className="space-y-2">
              <label
                for="from-token"
                className="text-xs font-semibold uppercase text-white"
              >
                Amount in $kuromi you receive 
              </label>
              <div className="relative">
                <input
                  type="number"
                  className="w-full p-5 pr-16 bg-transparent border rounded-lg border-white/20 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-5">
                  <img
                    src={Starcoin}
                    alt="Kuromi"
                    className="object-contain w-9 h-9"
                  />
                </div>
              </div>
            </div>
            <button className="flex items-center bg-[#8554FB] justify-center w-full px-3 py-5 font-semibold text-white uppercase rounded-lg bg-primary hover:bg-primary/80 disabled:opacity-50 disabled:cursor-not-allowed">
              Connect Wallet
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
 
  )
}

export default Hero;